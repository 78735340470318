import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {useEffect, useState} from 'react'
import {colors, gethomeLink} from '../../../constants'
// import StartpageLogoRainbow from '../../../images/startpage-logo-rainbow.svg'
import StartpageLogoAppBeta from '../../../images/startpage-logo-app-beta.svg'
import Inter from '../../../fonts/Inter'
import PhoneFullsizeEn from '../../../images/app/phones/phone-fullsize-en.svg'
import PhoneFullsizeDe from '../../../images/app/phones/phone-fullsize-nl.svg'
import PhoneFullsizeNl from '../../../images/app/phones/phone-fullsize-de.svg'
import PhoneShadow from '../../../images/app/phones/phone-fullsize-shadow.svg'
import {createMarkup} from '../../../util/datoCmsDataFactory'
import {getAllAppParams} from '../../../util/appMetadata'
import BigAppIcon from '../../../images/app/big-app-icon.svg'

const translations = {
    en: {
        title: `Private search and beyond`,
        description: `Search without tracking or profiling with the Startpage mobile app!`,
        qrCodeAlt: `Startpage mobile app QR code`,
        qrSectionDescription: `Use your phone’s camera to scan the QR code and download the Startpage app.`,
        availableOn: `Available on <a href="#">iOS</a> now. Android coming soon.`,
        and: `and`,
        qrCodeWaitlistTitle: `Join the waitlist`,
        qrSectionWaitlist: `Use your phone's camera to scan the QR code and join the waitlist for the Startpage app on iOS and Android.`,
    },
    de: {
        title: `Private Suche und mehr`,
        description: `Suche ohne Tracking oder Profilerstellung mit der mobilen Startpage App!`,
        qrCodeAlt: `Startpage mobile app QR code`,
        qrSectionDescription: `Scanne den QR-Code mit deinem Smartphone und lade die Startpage-App herunter.`,
        availableOn: `Jetzt auf <a href="#">iOS</a> verfügbar. Bald auch für Android.`,
        and: `and`,
        qrCodeWaitlistTitle: `Melde dich für die Warteliste an`,
        qrSectionWaitlist: `Scanne den QR-Code, um dich auf die Warteliste für die Startpage App für iOS und Android einzutragen.`,
    },
    nl: {
        title: `Privé zoeken en meer`,
        description: `Zoek zonder tracking of profilering met de Startpage mobiele app!`,
        qrCodeAlt: `Startpage mobile app QR code`,
        qrSectionDescription: `Gebruik de camera van je telefoon om de QR code te scannen en download de Startpage app.`,
        availableOn: `Nu beschikbaar voor <a href="#">iOS</a>. Binnenkort ook voor Android.`,
        and: `and`,
        qrCodeWaitlistTitle: `Meld je aan voor de wachtlijst`,
        qrSectionWaitlist: `Scan de QR-code en meld je aan voor de wachtlijst voor de Startpage-app voor iOS en Android.`,
    },
}

const UnSupported = ({os, browser, experimentId, languageCode}) => {
    const text = translations[languageCode]

    const site = useStaticQuery(graphql`
        query UnsupportedAppV1Query {
            blogQrCode: file(relativePath: {eq: "app/qr-codes/en/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCode: file(relativePath: {eq: "app/qr-codes/en/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCode: file(relativePath: {eq: "app/qr-codes/en/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCode: file(relativePath: {eq: "app/qr-codes/en/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCode: file(relativePath: {eq: "app/qr-codes/en/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCode: file(relativePath: {eq: "app/qr-codes/en/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCode: file(relativePath: {eq: "app/qr-codes/en/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCode: file(relativePath: {eq: "app/qr-codes/en/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCode: file(relativePath: {eq: "app/qr-codes/en/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCode: file(relativePath: {eq: "app/qr-codes/en/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCode: file(relativePath: {eq: "app/qr-codes/en/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCode: file(relativePath: {eq: "app/qr-codes/en/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCode: file(relativePath: {eq: "app/qr-codes/en/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            blogQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            blogQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const qrCodeMap = {
        en: {
            blog: site.blogQrCode.childImageSharp.fixed,
            discord: site.discordQrCode.childImageSharp.fixed,
            facebook: site.facebookQrCode.childImageSharp.fixed,
            instagram: site.instagramQrCode.childImageSharp.fixed,
            linkedin: site.linkedinQrCode.childImageSharp.fixed,
            mastodon: site.mastodonQrCode.childImageSharp.fixed,
            newsletter: site.newsletterQrCode.childImageSharp.fixed,
            organic: site.organicQrCode.childImageSharp.fixed,
            reddit: site.redditQrCode.childImageSharp.fixed,
            threads: site.threadsQrCode.childImageSharp.fixed,
            tiktok: site.tiktokQrCode.childImageSharp.fixed,
            twitter: site.twitterQrCode.childImageSharp.fixed,
            youtube: site.youtubeQrCode.childImageSharp.fixed,
        },
        nl: {
            blog: site.blogQrCodeNl.childImageSharp.fixed,
            discord: site.discordQrCodeNl.childImageSharp.fixed,
            facebook: site.facebookQrCodeNl.childImageSharp.fixed,
            instagram: site.instagramQrCodeNl.childImageSharp.fixed,
            linkedin: site.linkedinQrCodeNl.childImageSharp.fixed,
            mastodon: site.mastodonQrCodeNl.childImageSharp.fixed,
            newsletter: site.newsletterQrCodeNl.childImageSharp.fixed,
            organic: site.organicQrCodeNl.childImageSharp.fixed,
            reddit: site.redditQrCodeNl.childImageSharp.fixed,
            threads: site.threadsQrCodeNl.childImageSharp.fixed,
            tiktok: site.tiktokQrCodeNl.childImageSharp.fixed,
            twitter: site.twitterQrCodeNl.childImageSharp.fixed,
            youtube: site.youtubeQrCodeNl.childImageSharp.fixed,
        },
        de: {
            blog: site.blogQrCodeDe.childImageSharp.fixed,
            discord: site.discordQrCodeDe.childImageSharp.fixed,
            facebook: site.facebookQrCodeDe.childImageSharp.fixed,
            instagram: site.instagramQrCodeDe.childImageSharp.fixed,
            linkedin: site.linkedinQrCodeDe.childImageSharp.fixed,
            mastodon: site.mastodonQrCodeDe.childImageSharp.fixed,
            newsletter: site.newsletterQrCodeDe.childImageSharp.fixed,
            organic: site.organicQrCodeDe.childImageSharp.fixed,
            reddit: site.redditQrCodeDe.childImageSharp.fixed,
            threads: site.threadsQrCodeDe.childImageSharp.fixed,
            tiktok: site.tiktokQrCodeDe.childImageSharp.fixed,
            twitter: site.twitterQrCodeDe.childImageSharp.fixed,
            youtube: site.youtubeQrCodeDe.childImageSharp.fixed,
        },
    }

    const [qrCodeImage, setQrCodeImage] = useState(qrCodeMap[languageCode].organic)

    useEffect(() => {
        const appParams = getAllAppParams(experimentId)
        const {source} = appParams
        const image = qrCodeMap[languageCode][source]
        if (image) {
            setQrCodeImage(image)
        }
    }, [experimentId, languageCode, qrCodeMap])

    const getLocalizedImage = () => {
        switch (languageCode) {
            case 'de':
                return <PhoneFullsizeDe />
            case 'nl':
                return <PhoneFullsizeNl />
            default:
                return <PhoneFullsizeEn />
        }
    }
    return (
        <div
            className="unsupported"
            css={css`
                ${Inter};
                font-family: Inter, sans-serif;
                background: linear-gradient(180deg, #010102 0%, #6371f9 100%);
                height: 100vh;
                width: 100vw;
                padding: 24px;
                min-width: 1200px;
                position: fixed;
                @media (max-height: 730px) {
                    overflow-y: scroll;
                }
            `}
        >
            <div
                className="content"
                css={css`
                    max-width: 966px;
                    margin: 30px auto;
                    @media (max-height: 730px) {
                        transform: scale(0.9);
                        margin-top: -20px;
                    }
                    @media (max-height: 650px) {
                        transform: scale(0.8);
                        margin-top: -80px;
                        /* margin: 0 auto; */
                    }
                    @media (min-height: 1000px) {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                `}
            >
                <a href={gethomeLink()}>
                    <StartpageLogoAppBeta />
                </a>
                <div
                    className="body"
                    css={css`
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin: 40px 0 0;
                        /* @media (min-height: 1000px) {
                            max-height: 800px;
                            margin: auto 0;
                        } */
                    `}
                >
                    <div
                        className="text-section"
                        css={css`
                            max-width: 600px;
                            margin-right: 40px;
                            h1,
                            p,
                            span {
                                color: ${colors.whisperLight};
                            }
                            h1 {
                                font-size: 70px;
                                font-weight: 800;
                                line-height: 74px;
                                margin: 64px 0 0;
                            }
                            .description {
                                margin: 40px 0 100px;
                            }
                        `}
                    >
                        <h1>{text.title}</h1>
                        <p className="description">{text.description}</p>
                        <div
                            className="qr-code-section"
                            css={css`
                                background: ${colors.whisperLight};
                                border-radius: 24px;
                                padding: 16px 16px 13px;
                                display: flex;
                                align-items: center;
                                a,
                                span,
                                p {
                                    font-size: ${languageCode === 'nl' ? '14px' : '16px'};
                                    color: ${colors.black};
                                }
                                .gatsby-image-wrapper,
                                img {
                                    border-radius: 8px;
                                    overflow: hidden;
                                    height: 104px !important;
                                    width: 104px !important;
                                }
                            `}
                        >
                            <div
                                className="qr-code"
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    .gatsby-image-wrapper {
                                        transform: scale(1.2);
                                    }
                                `}
                            >
                                <Img alt={text.qrCodeAlt} fixed={qrCodeImage} />
                                <span
                                    className="url"
                                    css={css`
                                        font-size: 11px !important;
                                        color: ${colors.midnightMedium} !important;
                                        margin-top: 8px;
                                    `}
                                >
                                    app.startpage.com
                                </span>
                            </div>
                            <div
                                className="text"
                                css={css`
                                    margin-left: 24px;
                                    max-width: 400px;
                                    p,
                                    span {
                                        line-height: 22px;
                                    }
                                    span {
                                        font-weight: 600;
                                    }
                                    a {
                                        color: ${colors.brandBlueLight};
                                        text-decoration: none;
                                        font-weight: 700;
                                    }
                                `}
                            >
                                <h2
                                    className="qr-code-section-title"
                                    css={css`
                                        font-size: 24px;
                                        font-weight: 800;
                                        margin-bottom: 16px;
                                        color: ${colors.black};
                                    `}
                                >
                                    {text.qrCodeWaitlistTitle}
                                </h2>
                                <p
                                    css={css`
                                        /* margin: 0 0 12px; */
                                    `}
                                >
                                    {text.qrSectionWaitlist}
                                </p>
                                {/* <span dangerouslySetInnerHTML={createMarkup(text.availableOn)}>
                                    <a href="#"> iOs </a>
                                    {text.and}
                                    <a href="#"> Android</a>
                                </span> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="image"
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 40px;
                            position: relative;
                        `}
                    >
                        {getLocalizedImage()}
                        <BigAppIcon
                            css={css`
                                position: absolute;
                                bottom: 175px;
                                left: -36px;
                            `}
                        />
                        <PhoneShadow />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnSupported
